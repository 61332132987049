<template>
  <div>
    <CRow class="mb-3">
      <CCol sm="12" lg="12" xl="12" class="d-flex align-items-center justify-content-start">
        <CButton
          color="edit"
          class="mr-2"
          v-c-tooltip="{
            content: $t('label.return'),
            placement: 'top',
          }"
          size="sm"
          @click="Reset()"
        >
          <CIcon name="cil-chevron-left-alt" />
        </CButton>
        <h6 class="mt-2">
          {{ Recognition ? `${$t('label.nuevo')} ${$t('label.blPositioning')}: ${PositionItem.BlNumber}` : 
            isEdit ? `${$t('label.edit')} ${$t('label.blPositioning')}` 
            : `${$t('label.nuevo')} ${$t('label.blPositioning')}`}} 
        </h6>
      </CCol>
    </CRow>
    <CRow class="justify-content-center mt-3 px-3">
      <CCol sm="12">
        <CTabs variant="tabs" :active-tab="0">
          <CTab :title="$t('label.basicData')">
            <CCard class="card-border">
              <CRow class="justify-content-start px-3">
                <CCol sm="12" lg="6" v-if="!isEdit && !ValidateDispached && !Recognition">
                  <div class="form-group row">
                    <CCol sm="12" lg="4" class="px-0">
                        <label class="col-form-label-sm col-sm-12 col-lg-12 text-right px-0">{{$t('label.isItOutOfTime')}}</label>
                    </CCol>
                    <label class="col-sm-auto text-right pt-1">NO</label>
                    <div class="col-sm-auto d-flex align-items-center px-0">
                        <CSwitch
                            color="watch"
                            variant="3d"
                            type="checkbox"
                            :checked.sync="misTime"
                            @change.native="ChangeTime()"
                        />
                    </div>
                    <label class="col-sm-auto text-right pt-1">{{ `${this.$t('label.yes')}`}}</label>
                  </div>
                </CCol>
                <CCol sm="0" lg="6" v-if="!isEdit && !ValidateDispached && !Recognition">
                </CCol>
                <CCol sm="12" lg="4">
                  <CInput
                    size="sm"
                    v-uppercase
                    :label="$t('label.previousPosition')"
                    addLabelClasses="text-right"
                    :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                    :placeholder="''"
                    v-model="$v.PreviousPosition.$model"
                    :is-valid="hasError($v.PreviousPosition)"
                    :disabled="true"
                  >
                  </CInput>
                </CCol>
                <CCol sm="12" lg="4">
                    <CInput
                      size="sm"
                      v-uppercase
                      :label="$t('label.CurrentPosition')"
                      addLabelClasses="text-right"
                      :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                      :placeholder="''"
                      v-model="$v.CurrentPosition.$model"
                      :is-valid="hasError($v.CurrentPosition)"
                      :disabled="true"
                    >
                    </CInput>
                  </CCol>
                <CCol sm="12" lg="4">
                  <CInput
                    size="sm"
                    v-uppercase
                    :label="$t('label.bl')"
                    addLabelClasses="text-right"
                    :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                    :placeholder="$t('label.select')"
                    v-model="$v.BlNumber.$model"
                    :is-valid="hasError($v.BlNumber)"
                    :disabled="true"
                  >
                  </CInput>
                </CCol>
                <CCol sm="12" lg="4">
                  <CSelect
                    size="sm"
                    v-model="$v.PackagingId.$model"
                    :options="packagingOptions"
                    :label="$t('label.packaging')"
                    addLabelClasses="required text-right"
                    :horizontal="{
                      label: 'col-sm-12 col-lg-4', 
                      input: 'col-sm-12 col-lg-8'
                    }"
                    :placeholder="$t('label.select')"
                    :is-valid="hasError($v.PackagingId)"
                    :invalid-feedback="errorMessage($v.PackagingId)"
                    @change="getPackagingList"
                  />
                </CCol>
                <CCol sm="12" lg="4">
                  <CSelect
                    size="sm"
                    v-model="$v.Serial.$model"
                    :options="serialOptions"
                    :label="$t('label.serial')"
                    :addLabelClasses="this.hasSerial ? 'required text-right' : 'text-right'"
                    :horizontal="{
                      label: 'col-sm-12 col-lg-4', 
                      input: 'col-sm-12 col-lg-8'
                    }"
                    :placeholder="$t('label.select')"
                    :is-valid="hasError($v.Serial)"
                    :invalid-feedback="errorMessage($v.Serial)"
                    @change="getSerialList"
                  />
                </CCol>
                <CCol sm="12" lg="4">
                  <div class="form-group form-row">
                    <label class="text-right col-sm-12 col-lg-4 col-form-label-sm mb-0 required">{{`${$t('label.quantity')}`}}</label>
                    <div class="input-group col-sm-12 col-lg-8 input-group-sm">
                      <money
                        size="sm"
                        v-bind="measure" 
                        :class="!$v.Quantity.$dirty ? 'form-control' : ($v.Quantity.$error ? 'form-control is-invalid' : 'form-control is-valid')"
                        v-model="$v.Quantity.$model"
                        addLabelClasses="required text-right"
                        maxlength= "12"
                        :disabled = "hasSerial"
                      >
                      </money>
                      <div class="invalid-feedback" v-if="$v.Quantity.$error">
                        {{ errorMessage($v.Quantity) }}
                      </div>
                    </div>
                  </div>
                </CCol>
                <CCol sm="12" lg="4">
                  <CSelect
                    size="sm"
                    v-model="$v.EventReasonId.$model"
                    :options="EventOptions"
                    :label="$t('label.motive')"
                    addLabelClasses="required text-right"
                    :horizontal="{
                      label: 'col-sm-12 col-lg-4', 
                      input: 'col-sm-12 col-lg-8'
                    }"
                    :placeholder="$t('label.select')"
                    :is-valid="hasError($v.EventReasonId)"
                    :invalid-feedback="errorMessage($v.EventReasonId)"
                    @change="getEventService"
                  />
                </CCol>
                <CCol sm="12" lg="4">
                  <CSelect
                    size="sm"
                    v-model="$v.EventServiceId.$model"
                    :options="ServiceOptions"
                    :label="$t('label.service')"
                    :addLabelClasses="EventServiceList.length!=0 ? 'required text-right' : 'text-right'"
                    :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                    :placeholder="$t('label.select')"
                    :is-valid="hasError($v.EventServiceId)"
                    :invalid-feedback="errorMessage($v.EventServiceId)"
                    :disabled="EventServiceList.length==0"
                  />
                </CCol>
                <CCol sm="12" lg="4">
                  <CSelect
                    size="sm"
                    :label="$t('label.Area')"
                    addLabelClasses="required text-right"
                    :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                    :is-valid="hasError($v.AreaId)"
                    :placeholder="$t('label.select')"
                    v-model="$v.AreaId.$model"
                    :options="AreaOptions"
                  />
                </CCol>
                <CCol sm="12" lg="4">
                  <div class="form-group form-row">
                      <label class="text-right col-sm-12 col-lg-4 col-form-label-sm">{{`${$t('label.largo')}`}}</label>
                      <div class="input-group col-sm-12 col-lg-8 input-group-sm pl-2">
                          <money
                              size="sm"
                              v-bind="measureDecimals" 
                              :class="!$v.Length.$dirty ? 'form-control' : ($v.Length.$error ? 'form-control is-invalid' : 'form-control is-valid')"
                              v-model="$v.Length.$model"
                              addLabelClasses="text-right"
                              maxlength= "13"
                          >
                          </money>
                      </div>
                  </div>
                </CCol>
                <CCol sm="12" lg="4">
                  <div class="form-group form-row">
                      <label class="text-right col-sm-12 col-lg-4 col-form-label-sm">{{`${$t('label.width')}`}}</label>
                      <div class="input-group col-sm-12 col-lg-8 input-group-sm pl-2">
                          <money
                              size="sm"
                              v-bind="measureDecimals" 
                              :class="!$v.Width.$dirty ? 'form-control' : ($v.Width.$error ? 'form-control is-invalid' : 'form-control is-valid')"
                              v-model="$v.Width.$model"
                              addLabelClasses="text-right"
                              maxlength= "13"
                          >
                          </money>
                      </div>
                  </div>
                </CCol>
                <CCol sm="12" lg="4">
                  <div class="form-group form-row">
                      <label class="text-right col-sm-12 col-lg-4 col-form-label-sm">{{`${$t('label.height')}`}}</label>
                      <div class="input-group col-sm-12 col-lg-8 input-group-sm pl-2">
                          <money
                              size="sm"
                              v-bind="measureDecimals" 
                              :class="!$v.Height.$dirty ? 'form-control' : ($v.Height.$error ? 'form-control is-invalid' : 'form-control is-valid')"
                              v-model="$v.Height.$model"
                              addLabelClasses="text-right"
                              maxlength= "13"
                          >
                          </money>
                      </div>
                  </div>
                </CCol>
                <CCol sm="12" lg="4">
                  <div class="form-group form-row">
                    <label class="text-right col-sm-12 col-lg-4 col-form-label-sm px-0">{{`(M²)`}}</label>
                    <div class="input-group col-sm-12 col-lg-8 input-group-sm">
                      <money
                          size="sm"
                          v-bind="measure"
                          :class="!$v.Width.$dirty || !$v.Height.$dirty ? 'form-control' : 'form-control is-valid'"
                          v-model="CalculationMeters.SquareMeters"
                          addLabelClasses="required text-right"
                          maxlength= "13"
                          disabled
                      >
                      </money>
                    </div>
                  </div>
                </CCol>
                <CCol sm="12" lg="4">
                  <div class="form-group form-row">
                    <label class="text-right col-sm-12 col-lg-4 col-form-label-sm px-0">{{`(M³)`}}</label>
                    <div class="input-group col-sm-12 col-lg-8 input-group-sm">
                      <money
                          size="sm"
                          v-bind="measure"
                          :class="!$v.Volumen.$dirty ? 'form-control' : ($v.Volumen.$error ? 'form-control is-invalid' : 'form-control is-valid')"
                          v-model="CalculationMeters.Volumen"
                          addLabelClasses="required text-right"
                          maxlength= "13"
                          disabled
                      >
                      </money>
                      <div class="invalid-feedback" v-if="$v.Volumen.$error">
                      {{ errorMessage($v.Volumen) }}
                      </div>
                    </div>
                  </div>
                </CCol>
                <CCol sm="12" lg="4">
                  <CSelect
                    size="sm"
                    v-model="$v.MachineId.$model"
                    :options="MachineOptions"
                    :label="$t('label.machine')"
                    addLabelClasses="text-right"
                    :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                    :placeholder="$t('label.select')"
                    :is-valid="hasError($v.MachineId)"
                    :invalid-feedback="errorMessage($v.MachineId)"
                  />
                </CCol>
                <CCol sm="12" lg="4" v-if="!misTime">
                  <div class="form-group form-row mb-3">
                    <label class="col-form-label-sm col-sm-12 col-lg-4 mb-0 text-right required">{{`${$t('label.PositioningDate')}`}}</label>
                    <div class="input-group col-sm-12 col-lg-8 mb-0">
                      <vue-datepicker 
                        type="datetime"
                        header
                        :lang="this.$i18n.locale"
                        :editable="false"
                        :clearable="false"
                        format="DD/MM/YYYY HH:mm"
                        placeholder="DD/MM/YYYY HH:mm"
                        time-title-format="DD/MM/YYYY HH:mm"
                        v-model="$v.TransactionDate.$model"
                        :disabled-date="validateDateRange"
                        :append-to-body="false"
                        value-type="format"
                        :show-second="false"
                        class="w-100"
                      >
                        <template #input>
                          <CInput
                            v-uppercase
                            class="w-100 float-left mb-0"
                            :value="TransactionDate"
                            placeholder="DD/MM/YYYY HH:mm"
                            :is-valid="hasError($v.TransactionDate)"
                            size="sm"                      
                          >
                            <template #append-content>
                              <CIcon name="cil-calendar" />
                            </template>
                          </CInput>
                        </template>
                        <template #icon-calendar>
                          <div style="display: none"></div>
                        </template>
                      </vue-datepicker>
                    </div>
                  </div>
                </CCol>
                <CCol sm="12" lg="4" v-if="misTime">
                  <div class="form-group form-row mb-3">
                    <label class="col-form-label-sm col-sm-12 col-lg-4 mb-0 text-right required">{{`${$t('label.from')}`}}</label>
                    <div class="input-group col-sm-12 col-lg-8 mb-0">
                      <vue-datepicker 
                        type="datetime"
                        header
                        :lang="this.$i18n.locale"
                        :editable="false"
                        :clearable="false"
                        format="DD/MM/YYYY HH:mm"
                        placeholder="DD/MM/YYYY HH:mm"
                        time-title-format="DD/MM/YYYY HH:mm"
                        v-model="$v.fromDay.$model"
                        :disabled-date="validateDateRange"
                        :append-to-body="false"
                        value-type="format"
                        :show-second="false"
                        class="w-100"
                      >
                        <template #input>
                          <CInput
                            v-uppercase
                            class="w-100 float-left mb-0"
                            :value="fromDay"
                            placeholder="DD/MM/YYYY HH:mm"
                            :is-valid="hasError($v.fromDay)"
                            size="sm"                      
                          >
                            <template #append-content>
                              <CIcon name="cil-calendar" />
                            </template>
                          </CInput>
                        </template>
                        <template #icon-calendar>
                          <div style="display: none"></div>
                        </template>
                      </vue-datepicker>
                    </div>
                  </div>
                </CCol>
                <CCol sm="12" lg="4" v-if="misTime">
                  <div class="form-group form-row mb-3">
                    <label class="col-form-label-sm col-sm-12 col-lg-4 mb-0 text-right required">{{`${$t('label.to')}`}}</label>
                    <div class="input-group col-sm-12 col-lg-8 mb-0">
                      <vue-datepicker 
                        type="datetime"
                        header
                        :lang="this.$i18n.locale"
                        :editable="false"
                        :clearable="false"
                        format="DD/MM/YYYY HH:mm"
                        placeholder="DD/MM/YYYY HH:mm"
                        time-title-format="DD/MM/YYYY HH:mm"
                        v-model="$v.toDay.$model"
                        :disabled-date="validateDateRange"
                        :append-to-body="false"
                        value-type="format"
                        :show-second="false"
                        class="w-100"
                      >
                        <template #input>
                          <CInput
                            v-uppercase
                            class="w-100 float-left mb-0"
                            :value="toDay"
                            placeholder="DD/MM/YYYY HH:mm"
                            :is-valid="hasError($v.toDay)"
                            size="sm"                      
                          >
                            <template #append-content>
                              <CIcon name="cil-calendar" />
                            </template>
                          </CInput>
                        </template>
                        <template #icon-calendar>
                          <div style="display: none"></div>
                        </template>
                      </vue-datepicker>
                    </div>
                  </div>
                </CCol>
                <CCol sm="12" lg="4">
                  <CTextarea
                    size="sm"
                    rows="2"
                    :label="$t('label.observation')"
                    addLabelClasses="text-right"
                    :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                    :placeholder="$t('label.select')"
                    v-model="$v.Observation.$model"
                    v-uppercase
                    :is-valid="hasError($v.Observation)"
                    :invalid-feedback="errorMessage($v.Observation)"
                  />
                </CCol>
                <CCol sm="12" lg="4">
                  <CSelect
                    v-if="isEdit"
                    size="sm"
                    :options="statusOptions"
                    :value.sync="Status"
                    :label="$t('label.status')"
                    :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                    addLabelClasses="required text-right"
                    :is-valid="statusSelectColor"
                  />
                </CCol>
              </CRow>
            </CCard>
          </CTab>
        </CTabs>
      </CCol>
      <CCol sm="12" v-if="Serial && Serial != ''">
        <CTabs variant="tabs" :active-tab="0">
          <CTab :title="$t('label.inspectionSeals')">
            <CCard class="card-border">
              <CRow class="justify-content-center px-3">
                <CCol sm="12" lg="6">
                  <div class="form-group form-row">
                    <label class="col-form-label-sm col-sm-12 col-lg-3 mb-0 text-right">{{`${$t('label.Seal')} 1`}}</label>
                    <div class="input-group col-sm-12 col-lg-9">
                      <CInput
                        size="sm"
                        class="select-adjust pr-1"
                        v-uppercase
                        v-model="$v.Seal.Seal1.$model"
                        :is-valid="hasError($v.Seal.Seal1)"
                        style="width: 20%;"
                      >
                      </CInput>
                    </div>
                  </div>

                  <div class="form-group form-row">
                    <label class="col-form-label-sm col-sm-12 col-lg-3 mb-0 text-right">{{`${$t('label.Seal')} 2`}}</label>
                    <div class="input-group col-sm-12 col-lg-9">
                      <CInput
                        size="sm"
                        class="select-adjust pr-1"
                        v-uppercase
                        v-model="$v.Seal.Seal2.$model"
                        :is-valid="hasError($v.Seal.Seal2)"
                        style="width: 20%;"
                      >
                      </CInput>
                    </div>
                  </div>
                </CCol>
                <CCol sm="12" lg="6">
                  <div class="form-group form-row">
                    <label class="col-form-label-sm col-sm-12 col-lg-3 mb-0 text-right">{{`${$t('label.Seal')} 3`}}</label>
                    <div class="input-group col-sm-12 col-lg-9">
                      <CInput
                        size="sm"
                        class="select-adjust pr-1"
                        v-uppercase
                        v-model="$v.Seal.Seal3.$model"
                        :is-valid="hasError($v.Seal.Seal3)"
                        style="width: 20%;"
                      >
                      </CInput>
                    </div>
                  </div>
                  <div class="form-group form-row">
                    <label class="col-form-label-sm col-sm-12 col-lg-3 mb-0 text-right">{{`${$t('label.Seal')} 4`}}</label>
                    <div class="input-group col-sm-12 col-lg-9">
                      <CInput
                        size="sm"
                        class="select-adjust pr-1"
                        v-uppercase
                        v-model="$v.Seal.Seal4.$model"
                        :is-valid="hasError($v.Seal.Seal4)"
                        style="width: 20%;"
                      >
                      </CInput>
                    </div>
                  </div>
                </CCol>
              </CRow>
            </CCard>
          </CTab>
        </CTabs>
      </CCol>
      <CCol sm="12" class="d-flex align-items-center justify-content-end mb-2">
        <CButton
          square
          color="add"
          class="d-flex align-items-center"
          @click="Submit()"
          :disabled="isSubmit"
        >
          <CIcon name="checkAlt"/>{{$t('button.accept')}}
        </CButton>
      </CCol>
    </CRow>
  </div>
</template>
<script>
import ModalMixin from '@/_mixins/modal';
import UpperCase from '@/_validations/uppercase-directive';
import positioningRegisterValidations from '@/_validations/yard-management/warehouse/positioningRegisterValidations';
import { DateFormater, NumberFormater } from '@/_helpers/funciones';
import { mapState } from 'vuex';
import moment from 'moment';

function data() {
  return {
    //BL
    BlNumber: '',
    measure: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 0,
        masked: false,
        maxlength: 18,
    },
    measureDecimals: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false,
        maxlength: 18,
    },
    MaxPackagingQuantity: 0,
    MaxPackagingVolumen: 0,
    MaxWidthQuantity: 0,
    MaxHeightQuantity: 0,
    MaxLengthQuantity: 0,
    BlCargoMasterDetailId: '',
    BlCargoMasterDetailSerialId: '',
    Quantity: 0,
    hasSerial: false,
    PackagingId: '',
    PackagingList: [],
    Serial: '',
    SerialList: [],
    MachineId: '',
    MachineList: [],
    TransactionDate: '',
    AreaId: '',
    AreaList: [],
    Height: 0,
    Width: 0,
    Length: 0,
    Volumen: 0,
    loadingEdit: false,
    Continue: false,
    misTime: false,
    isSubmit: false,
    Validate: false,
    EventReasonId: '',
    EventList: [],
    EventServiceId: '',
    EventServiceList: [],
    PreviousPosition: '',
    CurrentPosition: '',
    fromDay: '',
    toDay: '',
    ValidPreviousDate: '',
    ValidLaterDate: '',
    previousDate: '',
    laterDate: '',
    Observation: '',
    YardName: '',
    Status: 0,
    CargoJson: {},
    Seal:{
      Seal1: '',
      Seal2: '',
      Seal3: '',
      Seal4: ''
    }
  };
}

async function getYardMachine() {
  await this.$http.get('MachineYard-list', { CompanyBranchId: this.CompanyBranchId, YardId: this.yardData.YardId })
    .then(response => {
      let List = response.data.data;
      this.MachineList = List&&List.length!=0 ? List : [];
    })
    .catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
}

async function getYardEventReasonList() {
  let Parameter = {
    EventId: process.env.VUE_APP_EVENT_POSITION,
    TpCargoId: this.dataBl.TpCargoId??'',
    YardId: this.yardData.YardId,
  };
  await this.$http.get('YardWEventReason-list', Parameter)
    .then(response => {
      let List = response.data.data;
      this.EventList = List&&List.length!=0 ? List : [];
    })
    .catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
}

async function getYardPackagingReceptionList() {
  let Parameter = {
    BlCargoId: this.dataBl.BlCargoId??'',
    YardId: this.yardData.YardId,
  };
  await this.$http.get('YardWCargoPackagingReception-by-BlCargoId', Parameter)
    .then(response => {
      let List = response.data.data;
      this.PackagingList = List&&List.length!=0 ? List : [];
    })
    .catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
}

function Submit() {
  try {
    this.$store.state.yardManagement.loading = true;
    this.isSubmit = true;
    this.Validate = true;
    this.$v.$touch();
    if (this.$v.$error){
      if (!this.misTime && this.TransactionDate && DateFormater.formatDateTimeWithoutSlash(this.TransactionDate) < this.ValidPreviousDate ) {
        throw this.$t('label.TheDateCannotBeLessThanTheDateOfReception');
      }
      if (!this.misTime && this.TransactionDate && DateFormater.formatDateTimeWithoutSlash(this.TransactionDate) > this.ValidLaterDate ) {
        throw this.$t('label.ErrorCurrentDatedPleaseCheck');
      }
      if (!this.misTime && this.TransactionDate && DateFormater.formatDateTimeWithoutSlash(this.TransactionDate) >= moment(this.dataBl.DispatchDate).format("YYYY-MM-DD HH:mm")) {
        throw this.$t('label.ErrorDispatchDatePleaseCheck');
      }
      throw this.$t('label.errorsPleaseCheck');
    }

    let BlCargoJson = {
      BlCargoId: this.dataBl.BlCargoId??'',
      BlCargoMasterDetailId: this.BlCargoMasterDetailId??'',
      BlCargoMasterDetailSerialId: this.BlCargoMasterDetailSerialId,
      YardAreaId: this.AreaId,
      EventServiceId: this.EventServiceId,
      EventReasonId: this.EventReasonId,
      Quantity: this.Quantity,
      Length: this.Length,
      Width: this.Width,
      Height: this.Height,
      Volumen: this.CalculationMeters.Volumen,
      SquareMeters: this.CalculationMeters.SquareMeters,
      YardCargoId: this.Recognition ? this.PositionItem?.YardCargoId : this.dataBl?.YardCargoId,
      MachineId: this.MachineId,
      Seal1: this.Seal.Seal1,
      Seal2: this.Seal.Seal2,
      Seal3: this.Seal.Seal3,
      Seal4: this.Seal.Seal4,
      TransactionBegin: this.misTime ? DateFormater.formatDateTimeWithoutSlash(this.fromDay) : undefined, 
      TransactionFinish: this.misTime ? DateFormater.formatDateTimeWithoutSlash(this.toDay) : undefined,
      TransactionDate: !this.misTime ? DateFormater.formatDateTimeWithoutSlash(this.TransactionDate) : undefined,
      Observation: this.Observation,
    }

    if (this.isEdit) {
      BlCargoJson.YardCargoEventDetailId = this.PositionItem.YardCargoEventDetailId;
      BlCargoJson.Status = this.Status;
    }

    let Method = this.isEdit ? 'PUT' : 'POST';
    let Route = this.isEdit ? 'YardWCargoPositioning-update' : 'YardWCargoPositioning';

    this.$http.ejecutar(Method, Route, BlCargoJson, { root: 'BlCargoJson' })
      .then((response) => {
        this.notifySuccess({text: response.data.data[0].Response});
        this.Reset();
        this.$emit("Update-list", this.PositionItem);
        this.isSubmit = false;
      }).catch((err) => {
        this.isSubmit = false;
        this.$store.state.yardManagement.loading = false;
        this.notifyError({text: err});
      })
  } catch (error) {
    this.isSubmit = false;
    this.$store.state.yardManagement.loading = false;
    this.notifyError({text: error});
  }
}

function validateDateRange(date) {
  return date < this.previousDate || date > this.laterDate;
}

function validateDateInitRange() {
  if(DateFormater.formatDateTimeWithoutSlash(this.fromDay) < this.ValidPreviousDate ) 
    return true;
  
  if(DateFormater.formatDateTimeWithoutSlash(this.fromDay) > this.ValidLaterDate ) 
    return true;
  
  if(DateFormater.formatDateTimeWithoutSlash(this.fromDay) >= moment(this.dataBl.DispatchDate).format("YYYY-MM-DD HH:mm")) 
    return true;
  
  if(DateFormater.formatDateTimeWithoutSlash(this.fromDay) >= DateFormater.formatDateTimeWithoutSlash(this.toDay) ) 
    return true;
  
  return false;
}

function validateDateFinalRange() {
  if(DateFormater.formatDateTimeWithoutSlash(this.toDay) < this.ValidPreviousDate ) 
    return true;
  
  if(DateFormater.formatDateTimeWithoutSlash(this.toDay) > this.ValidLaterDate ) 
    return true;
  
  if(DateFormater.formatDateTimeWithoutSlash(this.toDay) >= moment(this.dataBl.DispatchDate).format("YYYY-MM-DD HH:mm")) 
    return true;
  
  if(DateFormater.formatDateTimeWithoutSlash(this.toDay) <= DateFormater.formatDateTimeWithoutSlash(this.fromDay) ) 
    return true;
  
  return false;
}

function ChangeTime() {

  if(!this.misTime){
    this.fromDay = '';
    this.toDay = '';
    this.Continue = false;
    this.$v.$reset();
  }

  else{
    this.Continue = true;
    this.TransactionDate = ''
    this.$v.$reset();
  }

  this.AreaId = '';
}

function getNewData() {
  
  this.$v.$reset();
}

async function getdata(val) {
  this.loadingEdit = true;
  
  this.Observation = val.Observation ?? '';
  this.AreaId = val.YardAreaId ?? '';
  this.Status = val.FgActYardCargoEventDetail ? 1 : 0;

  this.Quantity = val.Quantity ?? 0; 
  this.PackagingId = val.PackagingId ??'';
  this.Serial = val.Serial;
  this.MachineId = val.MachineId ?? '';
  this.AreaId = val.YardAreaId;
  this.Height = val.Height ??0;
  this.Length = val.Length ??0;
  this.Width = val.Width ??0;
  this.EventReasonId = val.EventReasonId ?? '';
  this.EventServiceId = val.EventServiceId ?? '';
  this.Observation = val.Observation;
  this.PreviousPosition = val?.YardPreviousDetailPosJson?.map(PreviousDetail => {return PreviousDetail?.YardAreaName?.trim()})?.join(', ') ?? '';
  this.CurrentPosition = val.YardNewDetailPosJson?.map(NewDetailPos => {return NewDetailPos?.YardAreaName?.trim()})?.join(', ') ?? '';

  if(val.TransactionFinish != null){
    this.Continue = true;
    this.misTime = true; 
    this.fromDay = DateFormater.formatDateTimeWithSlash(val.TransactionBegin);
    this.toDay = DateFormater.formatDateTimeWithSlash(val.TransactionFinish);
  } 
  else
    this.TransactionDate = DateFormater.formatDateTimeWithSlash(val.TransactionDate);

  this.Seal.Seal1 = val.Seal1 ??'';
  this.Seal.Seal2 = val.Seal2 ??'';
  this.Seal.Seal3 = val.Seal3 ??'';
  this.Seal.Seal4 = val.Seal4 ??'';
  
  this.CargoJson = {
    YardCargoEventDetailId: this.PositionItem.YardCargoEventDetailId,
    YardAreaId: this.AreaId,
    YardCargoId: this.Recognition ? this.PositionItem?.YardCargoId : this.dataBl?.YardCargoId,
    EventServiceId: this.EventServiceId,
    EventReasonId: this.EventReasonId,
    MachineId: this.MachineId,
    TransactionDate: val.TransactionDate,
    Observation: this.Observation,
    Status: this.Status,
  }

  if (this.PackagingId!= '' && this.PackagingId) {
    let Packaging = this.PackagingList.find(item => item.PackagingId == this.PackagingId);

    if(Packaging?.SerialJson.length>0){
      this.hasSerial = true;
      this.Quantity = 1;
      this.SerialList = Packaging?.SerialJson ?? [];
    } 

    if (this.Serial!= '' && this.Serial) {
      let Serial = this.SerialList.find(item => item.Serial == this.Serial);
      this.MaxWidthQuantity = Serial?.Width ?? 0;
      this.MaxHeightQuantity = Serial?.Height ?? 0;
      this.MaxLengthQuantity = Serial?.Length ?? 0;
      this.BlCargoMasterDetailSerialId = Serial?.BlCargoMasterDetailSerialId??'';
    }

    this.BlCargoMasterDetailId = Packaging?.BlCargoMasterDetailId??'';
    this.MaxPackagingQuantity = Packaging?.Quantity ?? 0;
    this.MaxPackagingVolumen = Packaging?.Volumen ?? 0;
  }

  if (this.EventReasonId) {
    let EventReasonData = this.EventList.find(item => item.EventReasonId == this.EventReasonId);
    this.EventServiceList = EventReasonData?.EventServiceJson ? EventReasonData?.EventServiceJson : [];
    
  }

  this.Validate = true;
  this.$v.$touch();
  this.loadingEdit = false;
}

function getDateRange() {
  this.previousDate = !this.Recognition ? DateFormater.formatDateRange(this.dataBl.ReceptionDate) :
    DateFormater.formatDateRange(this.PositionItem.ReceptionDate);
  this.laterDate = new Date();
  this.ValidPreviousDate = !this.Recognition ?
    DateFormater.formatDateTimeWithoutSlash(DateFormater.formatDateTimeWithSlash(this.dataBl.ReceptionDate)) :
    DateFormater.formatDateTimeWithoutSlash(DateFormater.formatDateTimeWithSlash(this.PositionItem.ReceptionDate));
  this.ValidLaterDate = DateFormater.formatDateTimeWithoutSlash(DateFormater.formatTimeZoneToDateTime(this.laterDate));
}

function statusSelectColor() {
  return this.Status === 1;
}

function Reset() {
  this.misTime = false;
  this.fromDay = '';
  this.toDay = '';
  this.EventReasonId = '';
  this.EventList = [];
  this.EventServiceId = '';
  this.EventServiceList = [];
  this.PreviousPosition = '';
  this.hasSerial = false;
  this.CurrentPosition = '';
  this.MaxPackagingQuantity = 0;
  this.MaxPackagingVolumen = 0;
  this.MaxWidthQuantity = 0;
  this.MaxHeightQuantity = 0;
  this.MaxLengthQuantity = 0;
  this.BlCargoMasterDetailId = '';
  this.BlCargoMasterDetailSerialId = '';
  this.TransactionDate = '';
  this.ValidPreviousDate = '';
  this.ValidLaterDate = '';
  this.previousDate = '';
  this.laterDate = '';
  this.Observation = '';
  this.YardName = '';
  this.MachineId = '';
  this.MachineList = [];
  this.AreaId = '';
  this.AreaList = [];
  this.Status = 0;
  this.CargoJson = {};
  this.Quantity = 0;
  this.PackagingId = '';
  this.Seal = {
    Seal1: '',
    Seal2: '',
    Seal3: '',
    Seal4: ''
  };
  this.Serial = '';
  this.Height = 0;
  this.Width = 0;
  this.Length = 0;
  this.Validate = false;
  this.$v.$reset();
  this.$emit("Close");
}

function getPackagingList(event) {
  this.SerialList = [];
  this.Serial = '';
  this.hasSerial = false;
  this.Quantity = 0;
  this.BlCargoMasterDetailId = '';

  if (event.target.value!= '' && event.target.value) {
    let Packaging = this.PackagingList.find(item => item.PackagingId == event.target.value);

    if(Packaging?.SerialJson.length>0){
      this.hasSerial = true;
      this.Quantity = 1;
      this.SerialList = Packaging?.SerialJson ?? [];
    } 

    this.BlCargoMasterDetailId = Packaging?.BlCargoMasterDetailId??'';
    this.MaxPackagingQuantity = Packaging?.Quantity ?? 0;
    this.MaxPackagingVolumen = Packaging?.Volumen ?? this.dataBl.Volumen;
  }
}

function getSerialList(event) {
 if (event.target.value!= '' && event.target.value) {
    let Serial = this.SerialList.find(item => item.Serial == event.target.value);
    this.MaxWidthQuantity = Serial.Width ?? 0;
    this.MaxHeightQuantity = Serial.Height ?? 0;
    this.MaxLengthQuantity = Serial.Length ?? 0;
    this.BlCargoMasterDetailSerialId = Serial?.BlCargoMasterDetailSerialId??'';
  }
  else{
    this.MaxWidthQuantity = 0;
    this.MaxHeightQuantity = 0;
    this.MaxLengthQuantity = 0;
    this.BlCargoMasterDetailSerialId = '';
  }
}

function getEventService(event) {
  this.EventReasonId = event.target.value;
  this.EventServiceId = '';
  this.EventServiceList = [];
  if (this.EventReasonId) {
    let EventReasonData = this.EventList.find(item => item.EventReasonId == this.EventReasonId);
    this.EventServiceList = EventReasonData?.EventServiceJson ? EventReasonData.EventServiceJson : [];
  }
}

//Computeds:
function ValidateDispached() {
  return process.env.VUE_APP_YARD_CARGO_STATUS_DISPACHED==this.YardCargoBlStatus?.toUpperCase();
}

function ContainerSize() {
  return this.Recognition ? this.PositionItem?.Size != 20  : this.dataBl?.Size != 20;
}

function EventOptions() {
  if(this.EventList.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    let chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
    this.EventList.map(function(e){
      chart.push({
        value: e.EventReasonId,
        label: e[`EventReasonName${_lang}`],
      })
    })
    return chart;
  }
}

function ServiceOptions() {
  if(this.EventServiceList.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    let chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
    this.EventServiceList.map(function(e){
      chart.push({
        value: e.EventId,
        label: e[`EventName${_lang}`],
      })
    })
    return chart;
  }
}


async function getYardAreaList() {
  await this.$http.get('YardWAreaModule-by-YardId', { YardId: this.yardData.YardId, })
    .then(response => {
      let List = response.data.data;
      this.AreaList = List&&List.length!=0 ? List : [];
    })
    .catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
}

function packagingOptions(){
    let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
    var chart = [{
    value: '',
    label: this.$t('label.select'),
    }]
    this.PackagingList.map((e) => {
    chart.push({
        value: e.PackagingId,
        label: e[`PackagingName${_lang}`]
    });
    })
    return chart;
}

function serialOptions(){
    let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
    var chart = [{
    value: '',
    label: this.$t('label.select'),
    }]
    this.SerialList.map((e) => {
    chart.push({
        value: e.Serial,
        label: e.Serial
    });
    })
    return chart;
}

function MachineOptions() {
  if(this.MachineList.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    let chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.MachineList.map(function(e){
      chart.push({
        value: e.MachineId,
        label: e.MachineName,
      })
    })
    return chart;
  }
}

function AreaOptions() {
  if(this.AreaList.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    let chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
    this.AreaList.map(function(e){
      chart.push({
        value: e.YardAreaId,
        label: `${e[`TpYardAreaName${_lang}`]} (${e.YardAreaName})`,
      })
    })
    return chart;
  }
}

function CalculationMeters() {
  this.Volumen = NumberFormater.setNum(this.Length) * NumberFormater.setNum(this.Width) * NumberFormater.setNum(this.Height);
  return {
    SquareMeters: NumberFormater.setNum(this.Length) * NumberFormater.setNum(this.Width),
    Volumen: NumberFormater.setNum(this.Length) * NumberFormater.setNum(this.Width) * NumberFormater.setNum(this.Height),
  }
}

export default {
  name: 'positioning-register',
  components: {
   },
  props: { Active: Boolean, Recognition: Boolean, isEdit: Boolean, PositionItem: Object },
  data,
  mixins: [
    ModalMixin,
  ],
  validations(){
    return positioningRegisterValidations(this.ValidPreviousDate, this.ValidLaterDate,
      moment(this.dataBl.DispatchDate).format("YYYY-MM-DD HH:mm"), this.EventServiceList.length!=0, this.fromDay, 
      this.toDay, this.misTime, this.MaxPackagingQuantity, this.MaxWidthQuantity, this.MaxHeightQuantity, 
      this.MaxLengthQuantity, this.hasSerial, this.MaxPackagingVolumen);
  },
  directives: UpperCase,
  methods: {
    getYardAreaList,
    getYardMachine,
    getYardEventReasonList,
    Submit,
    getEventService,
    getPackagingList,
    validateDateRange,
    getNewData,
    getdata,
    getDateRange,
    statusSelectColor,
    Reset,
    ChangeTime,
    getYardPackagingReceptionList,
    getSerialList,
  },
  computed: {
    packagingOptions,
    serialOptions,
    CalculationMeters,
    ServiceOptions,
    validateDateInitRange,
    validateDateFinalRange,
    MachineOptions,
    EventOptions,
    AreaOptions,
    ValidateDispached,
    ContainerSize,
    ...mapState({
      CompanyBranchId: state => state.auth.branch.CompanyBranchId,
      YardCargoBlStatus: state => state.yardManagement.dataBl.YardCargoBlStatus,
      dropBlMovement: state => state.yardManagement.dropBlMovement,
      dataBl: state => state.yardManagement.dataBl,
      yardData: state => state.yardManagement.yardData,
    })
  },
  watch: {
    Active: async function(val){
      if (val) {
        this.$store.state.yardManagement.loading = true;
        this.YardName = this.dataBl.YardName;
        this.BlNumber =  this.dataBl.BlNro??'';
        await this.getDateRange();
        await this.getYardMachine();
        await this.getYardEventReasonList();
        await this.getYardAreaList();
        await this.getYardPackagingReceptionList();
        
        if (this.isEdit) {
          await this.getdata(this.PositionItem);
        }else{
        }

        

        this.$store.state.yardManagement.loading = false;
      }
    },
    TransactionDate: async function(val){
      try{
        if(val){
          if (DateFormater.formatDateTimeWithoutSlash(val) < this.ValidPreviousDate ) {
            throw this.$t('label.TheDateCannotBeLessThanTheDateOfReception');
          }
          if (DateFormater.formatDateTimeWithoutSlash(val) > this.ValidLaterDate ) {
            throw this.$t('label.ErrorCurrentDatedPleaseCheck');
          }
          if (DateFormater.formatDateTimeWithoutSlash(val) >= moment(this.dataBl.DispatchDate).format("YYYY-MM-DD HH:mm")) {
            throw this.$t('label.ErrorDispatchDatePleaseCheck');
          }
          if (DateFormater.formatDateTimeWithoutSlash(val) > this.ValidLaterDate ) {
            throw this.$t('label.ErrorCurrentDatedPleaseCheck');
          }
        }
      }catch(e){
        this.notifyError({text: e});
      }
    },
    fromDay: async function(val){
      try{
        if(val){
          if(!this.loadingEdit){
            this.Continue = false;
          }

          if (DateFormater.formatDateTimeWithoutSlash(val) < this.ValidPreviousDate ) {
            throw this.$t('label.TheDateCannotBeLessThanTheDateOfReception');
          }
          if (DateFormater.formatDateTimeWithoutSlash(val) > this.ValidLaterDate ) {
            throw this.$t('label.ErrorCurrentDatedPleaseCheck');
          }
          if (DateFormater.formatDateTimeWithoutSlash(val) >= moment(this.dataBl.DispatchDate).format("YYYY-MM-DD HH:mm")) {
            throw this.$t('label.ErrorDispatchDatePleaseCheck');
          }
          if (DateFormater.formatDateTimeWithoutSlash(val) >= DateFormater.formatDateTimeWithoutSlash(this.toDay) ) {
            throw this.$t('label.ErrorFromToPleaseCheck');
          }
        }
      }catch(e){
        this.notifyError({text: e});
      }
    },
    toDay: async function(val){
      try{
        if(val){
          if(!this.loadingEdit){
            this.Continue = false;
          }
          
          if (DateFormater.formatDateTimeWithoutSlash(val) < this.ValidPreviousDate ) {
            throw this.$t('label.TheDateCannotBeLessThanTheDateOfReception');
          }
          if (DateFormater.formatDateTimeWithoutSlash(val) > this.ValidLaterDate ) {
            throw this.$t('label.ErrorCurrentDatedPleaseCheck');
          }
          if (DateFormater.formatDateTimeWithoutSlash(val) >= moment(this.dataBl.DispatchDate).format("YYYY-MM-DD HH:mm")) {
            throw this.$t('label.ErrorDispatchDatePleaseCheck');
          }
          if (DateFormater.formatDateTimeWithoutSlash(val) <= DateFormater.formatDateTimeWithoutSlash(this.fromDay) ) {
            throw this.$t('label.ErrorToFromPleaseCheck');
          }
        }
      }catch(e){
        this.notifyError({text: e});
      }
    },
    dropBlMovement: function (NewVal, OldVal) {
      if (OldVal == 2) {
        this.Reset();
      }
    }
  },
};
</script>
<style>
  .text-size-11{
    font-size: 11px;
  }
  .card-border{
    padding: 20px;
    background-color: #fff;
    border-color: #c4c9d0;
    border-top: none;
    border-radius: 0 0 0.25rem 0.25rem;
  }
</style>