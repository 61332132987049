import { required, maxLength } from 'vuelidate/lib/validators';
import { onlyAlphanumeric5 } from '@/_validations/validacionEspeciales'
import { DateFormater, NumberFormater } from "@/_helpers/funciones";
import { minValue, maxValue } from "vuelidate/lib/validators";

export default (  PreviousDate, LaterDate, DispatchDate, EventService, fromDay, toDay, misTime, MaxPackagingQuantity, MaxWidthQuantity, MaxHeightQuantity, MaxLengthQuantity, hasSerial, MaxPackagingVolumen) => {
    let EventServiceValid = EventService ? { required } : true;
    let AreaValid = { required };
    let Serial= hasSerial ? { required } : true;
    

    let TransactionDate = !misTime ?
    { required, RangeDate(value){
        return(DateFormater.formatDateTimeWithoutSlash(value) >= PreviousDate &&
            DateFormater.formatDateTimeWithoutSlash(value) <= LaterDate && 
            DateFormater.formatDateTimeWithoutSlash(value) < DispatchDate)
    } } : true;

    let from = misTime ?
    { required, TimeValidate(value){
        return(DateFormater.formatDateTimeWithoutSlash(value) >= PreviousDate &&
            DateFormater.formatDateTimeWithoutSlash(value) <= LaterDate && 
            DateFormater.formatDateTimeWithoutSlash(value) < DispatchDate 
        )
    },
    RangeDate(value){
        return toDay ? DateFormater.formatDateTimeWithoutSlash(value) <  DateFormater.formatDateTimeWithoutSlash(toDay) : true;
    } } : true;

    let to= misTime ?
    { required, TimeValidate(value){
        return(DateFormater.formatDateTimeWithoutSlash(value) >= PreviousDate &&
            DateFormater.formatDateTimeWithoutSlash(value) <= LaterDate && 
            DateFormater.formatDateTimeWithoutSlash(value) < DispatchDate 
        )
    },
    RangeDate(value){
       return fromDay ? DateFormater.formatDateTimeWithoutSlash(value) >  DateFormater.formatDateTimeWithoutSlash(fromDay) : true;
    } } : true;

    return {
        //Bl
        BlNumber: {},
        PackagingId: {required},
        Serial: Serial,
        Quantity: { 
            minValue: minValue(1),
            maxValue: maxValue(MaxPackagingQuantity == 0 ? 1000000 : MaxPackagingQuantity),
            NotNegative(Value) { return !Number.isNaN(Value) && NumberFormater.setNum(Value) < 0 ? false : true },
            required(Value) { return NumberFormater.setNum(Value) > 0 || NumberFormater.setNum(Value) < 0 ? true : false }, 
        },
        EventReasonId: { required },
        MachineId: {},
        TransactionDate: TransactionDate,
        fromDay: from,
        toDay: to,
        Height: { 
            maxValue: maxValue(MaxHeightQuantity == 0 ? 1000000 : MaxHeightQuantity),
            NotNegative(Value) { return !Number.isNaN(Value) && NumberFormater.setNum(Value) < 0 ? false : true },
        },
        Width: { 
            maxValue: maxValue(MaxWidthQuantity == 0 ? 1000000 : MaxWidthQuantity),
            NotNegative(Value) { return !Number.isNaN(Value) && NumberFormater.setNum(Value) < 0 ? false : true },
        },
        Length: { 
            maxValue: maxValue(MaxLengthQuantity == 0 ? 1000000 : MaxLengthQuantity),
            NotNegative(Value) { return !Number.isNaN(Value) && NumberFormater.setNum(Value) < 0 ? false : true },
        },
        Volumen: { 
            maxValue: maxValue(MaxPackagingVolumen == 0 ? 1000000 : MaxPackagingVolumen),
        },
        YardName: {},
        AreaId: AreaValid,
        EventServiceId: EventServiceValid,
        PreviousPosition: {},
        CurrentPosition: {},
        Observation: { onlyAlphanumeric5 },
        Seal:{
            Seal1: {},
            Seal2: {},
            Seal3: {},
            Seal4: {}
        }
    }
}